export const detectBrowserAndDevice = () => {
    const UserAgent = navigator.userAgent;
    const device = {
        iPad: /iPad/.test(UserAgent),
        iPhone: /iPhone/.test(UserAgent),
        Android: /Android/.test(UserAgent),
        Windows: /Windows/.test(UserAgent),
        macOS: /Macintosh/.test(UserAgent),
        Linux: /Linux/.test(UserAgent),
        BlackBerry: /BlackBerry/.test(UserAgent),
        iPod: /iPod/.test(UserAgent),
        Samsung: /Samsung/.test(UserAgent),
        Nokia: /Nokia/.test(UserAgent),
        Sony: /Sony/.test(UserAgent),
        HTC: /HTC/.test(UserAgent),
        LG: /LG/.test(UserAgent),
        Motorola: /Motorola/.test(UserAgent),
        Nexus: /Nexus/.test(UserAgent),
        Kindle: /Kindle/.test(UserAgent),
        FirePhone: /Fire Phone/.test(UserAgent)
    };

    let browserName;

    // Checking for browser
    if (/Firefox\//.test(UserAgent)) {
        browserName = "Mozilla Firefox";
    } else if (/Edg\//.test(UserAgent)) {
        browserName = "Microsoft Edge";
    } else if (/Chrome\//.test(UserAgent)) {
        browserName = "Google Chrome";
    } else if (/Safari\//.test(UserAgent)) {
        browserName = "Apple Safari";
    } else if (/OPR\//.test(UserAgent)) {
        browserName = "Opera";
    } else if (/Trident\//.test(UserAgent)) {
        browserName = "Microsoft Internet Explorer";
    } else if (/UCBrowser\//.test(UserAgent)) {
        browserName = "UC Browser";
    } else if (/SamsungBrowser\//.test(UserAgent)) {
        browserName = "Samsung Internet";
    } else if (/MQQBrowser\//.test(UserAgent)) {
        browserName = "QQ Browser";
    } else if (/WeChat\//.test(UserAgent)) {
        browserName = "WeChat Browser";
    } else if (/Yandex\//.test(UserAgent)) {
        browserName = "Yandex Browser";
    } else if (/AppleWebKit\//.test(UserAgent)) {
        browserName = "WebKit-based Browser (unknown)";
    } else {
        browserName = "Unknown Browser";
    }
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, '');
    uid += navigator_info.plugins.length;
    uid += screen_info.height || '';
    uid += screen_info.width || '';
    uid += screen_info.pixelDepth || '';
    // Constructing the result object
    const result = {
        devicename: browserName + Object.keys(device).find(dev => device[dev]) || "Unknown Device",
        deviceid: uid,
        domain: window.location.origin,
        // companySlug:window.___E_TT_Client.companySlug
    };

    return result;
}