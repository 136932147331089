import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addCommentForum, getALLNotifactionCount, getNotifactionCount, getSaveFormService } from "../Utils/services";

export const Globalinformation = createContext();

const MainSectionProvider = (props) => {
  const [addcomment, setAddcomment] = useState();
  const [fileteValue, setFilterValue] = useState("")
  const [tagsearch, settagsearch] = useState("")
  const [translateLanguage, setTranslateLanguage] = useState("")
  const language = localStorage.getItem('lng')
  const [NotificationMap, setNotificationMap] = useState({})

  const dataPost = localStorage.getItem('countrypostallow')



  const [saveForm, setSaveForm] = useState([]);
  //console.log((addcomment, "hello");

  //-----home section--- //
  const getSavedForumAPiData = async () => {
    try {
      const result = await getSaveFormService();
      setSaveForm(result.res.data.savedForum);
    } catch (error) {
      //console.log((error);
    }
  };

  const handleAddcomment = async (id) => {
    const result = await addCommentForum({
      threadId: id,
      comment: addcomment,
    });
    //console.log((result, "hello");
  };

  //like and dislike globaly //

  const getNotificationCount = async () => {
    try {
      const result = await getNotifactionCount();
      const resultAll = await getALLNotifactionCount()
      setNotificationMap({
        ...NotificationMap,
        Forum: resultAll.res.data.thread,
        Resources: resultAll.res.data.resource,
        events: resultAll.res.data.event,
        Newsfeed: resultAll.res.data.newsfeed,
        Stories: resultAll.res.data.story,
        Gallery: resultAll.res.data.gallery,
        Toolkits: resultAll.res.data.toolkit,
        // gethelp: result.res.data,
        notification: result.res.data
      });
    } catch (error) {
      //console.log((error);
    }
  };

  useEffect(() => {
    getSavedForumAPiData();
    getNotificationCount()
    if (language === 'English') {
      setTranslateLanguage("en")
    }
    else if (language === 'हिंदी') {
      setTranslateLanguage("hi")
    }
    else if (language === 'বাংলা') {
      setTranslateLanguage("bn")
    }
    else if (language === 'नेपाली') {
      setTranslateLanguage("ne")
    }
    else if (language === 'অসমীয়া') {
      setTranslateLanguage("as")
    }
    else if (language === 'Bahasa') {
      setTranslateLanguage("id")
    }
    else if (language === 'ไทย') {
      setTranslateLanguage("th")
    }
    else if (language === 'မြန်မာစာ') {
      setTranslateLanguage("my")
    }

    else {
      setTranslateLanguage("en")
    }
    //console.log((translateLanguage,"trans")
  }, []);




  //console.log((translateLanguage,"trans")




  return (
    <>
      <Globalinformation.Provider
        value={{
          saveForm: saveForm,
          getSavedForumAPiData: getSavedForumAPiData,
          handleAddcomment: handleAddcomment,
          setAddcomment: setAddcomment,
          addcomment: addcomment,
          setFilterValue: setFilterValue,
          fileteValue: fileteValue,
          settagsearch: settagsearch,
          tagsearch: tagsearch,
          translateLanguage: translateLanguage,
          NotificationMap: NotificationMap,
          setNotificationMap:setNotificationMap
        }}
      >
        {props.children}
      </Globalinformation.Provider>
    </>
  );
};

export default MainSectionProvider;
