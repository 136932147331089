import Auth from "./Auth";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import Layout from "./Layout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../Utils/auth-context";
import { lazy, useContext, useEffect, useState } from "react";
import ForgotPassword from "./Auth/ForgotPassword";
import ChangePassword from "./Auth/ChangePassword";
import OtpVerification from "./Auth/OtpVerification";

import LoginOtpVerification from "./Auth/LoginOtpVerification";
import Signup from "./Auth/Signup";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import { onMessageListener } from "./firebaseInit";
import Notifications from "./Notification/Notifications";
import ReactNotificationComponent from "./Notification/ReactNotification";
import PrivacyPolicy from "./Auth/PrivacyPolicy";
import ResetPassword from "./Profile/Resetpassword";
import UIkit from "./100msMeet";
import { Globalinformation } from "../Common/MainSectionProvider";
// import AgoraUI from "./AgoraMeeting";

const App = () => {
  const authCtx = useContext(AuthContext);
  const initialToken = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const { NotificationMap, setNotificationMap } = useContext(Globalinformation);
  const [history, setHistory] = useState([]);

  // Now, the effect will run whenever the route changes.

  onMessageListener()
    .then((payload) => {
      console.log(payload, "payload");
      setNotificationMap({
        ...NotificationMap,
        gethelp: NotificationMap["gethelp"] ? NotificationMap["gethelp"] + 1 : 1
      })
      toast.info(
        // <Link to={{pathname: 'Chat', state: payload?.notification?.body}} >
        <Display
          title={payload?.notification?.title}
          body={payload?.notification?.body}
        />
        // </Link>
      );

      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        setShow: true,
      });
      // console.log(payload,"payload");
      // if(payload.notification.title === "chat notify"){
      // setNotificationLength(prev => [...prev, payload])
      // let lastElement = notificationLength?.length + 1;
      // localStorage.setItem("Notificationlength", lastElement);
      // }
    })
    .catch((err) => console.log("failed: ", err));

  const Display = ({ title, body }) => {
    return (
      <div>
        <h4>{title}</h4>
        {/* {/ <p>{body}</p> /} */}
      </div>
    );
  };
  if (
    (history.length === 0 ||
      history[history.length - 1] !== window.location.pathname) &&
    window.location.pathname !== "/login"
  ) {
    setHistory((prevHistory) => [...prevHistory, window.location.pathname]);
  }
  return (
    <Router>
      {show && (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      )}
      <Notifications />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route
          path="/login"
          element={<Auth history={history} setHistory={setHistory} />}
        />
        <Route path="/signup" element={<Signup />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/otp" element={<OtpVerification />} />
        <Route path="/loginOtp" element={<LoginOtpVerification />} />

        {authCtx.isLoggedIn ? (
          <>
            <Route path="/*" element={<Layout />} />
            <Route path="/glpmeeting/:slug" element={<UIkit />} />

            {/* <Route path="/resetpassword" element={<ResetPassword />} /> */}
          </>
        ) : (
          <Route
            path="/login"
            element={<Auth history={history} setHistory={setHistory} />}
          />
        )}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
