import axios from "axios";
import {
  LOGIN,
  CHANGEPASSWORD_API,
  OTPVERIFICATION_API,
  FORGOTPASSWORD_API,
  OTPVERIFICATION_API_LOGIN,
  LOGIN_FIRST,
  GET_SECTOR,
  GET_LANGUAGE,
  SIGNUP,
  FORMSERVICE,
  LiKE_END_UNLIKE_FORMSER,
  BOOK_MARK,
  SAVE_FORM,
  GET_TAG,
  GET_COMMENTS,
  ADD_COMMENTS,
  GALLERYSERVICE,
  LiKEGALLERY,
  GET_GALLERY_COMMENTS,
  ADD_COMMENT_GALLERY,
  DELETE_COMMENT_GALLERY,
  DELETE_POST_GALLERY,
  REPORT_POST_GALLERY,
  REPORT_COMMENT_GALLERY,
  EDIT_POST_GALLERY,
  PROFILE,
  GET_RESOURCE,
  GET_CATEGARY,
  GET_TOOLKIT,
  GET_NOTIFACTION,
  NEWSFEED,
  DEL_FORM_COMMENTS,
  EDIT_FORM_COMMENTS,
  REPORT_FORM_COMMENT,
  EVENTS,
  STORIES,
  LIKESTORIES,
  ADDCOMMENTSTORY,
  COMMENTSTORY,
  EDIT_COMENT_STORY,
  DELETE_STORY_COMMENT,
  LIKE_STORY_COMMENT,
  NEWSFEEDFILTER,
  ADD_POST,
  DIRETORY,
  MESSSAGES,
  SEND_MESSSAGES,
  ABOUT_US,
  DESCLAIMER,
  CODE_OF_CONDUCT,
  FAQ,
  FILTER_POST,
  SEARCH_STORY_POST,
  SEARCH_TOOLKIT,
  SEARCH_POST_GALLERY,
  FILTER_FORMSERVICE,
  SEARCH_FORM,
  EDIT_PROFILE,
  EDIT_AVATAR_PROFILE,
  SENDVERIFYCODE,
  VERIFYCODE,
  EDIT_POST_GALLERY_COMMENT,
  LIKE_REPLY,
  SUGGEST,
  likereply,
  LIKE_RPLY_STORY,
  BLOCK,
  BLOCKLIST,
  SEARCHNEWSFEED,
  DELETE_PROFILE,
  VIEW_COUNT_RESOURCE,
  DOWNLOAD_COUNT_RESOURCE,
  VIEW_COUNT_TOOLKIT,
  DOWNLOAD_COUNT_TOOLKIT,
  NOTIFICATION_READ,
  DIRECTORYSEARCH,
  SKILLTRAINNING,
  SKILLSESSION,
  COPLETE_SEBSESSION,
  GET_SUB_SESSION,
  LOGOUT,
  COURES_FILTER,
  COURSE_SEARCH,
  ENROLL_COURSE,
  DOWNLOADPDF,
  ALIASMATCH,
  POLL,
  BOOKMARKNEEWSFEED,
  GETSAVENEWS,
  MENTIONAPI,
  GETALLACTIVETHREATS,
  GETALLPOLL,
  USERREPORTSPI,
  DOWNLOADCOURSEAPI,
  RESRT_PASSWORD,
  GET_MEETING_CODE,
  TASKCOMPLETE,
  POLLV2,
  NOTIFICATION_COUNT,
  SINGLE_RESOURCE,
  SINGLE_NOTIFICATION_READ,
  NOTIFICATION_ALL_COUNT,
  FCM_TOKEN_API,
} from "./routes";

/**=================================================Constants====================================================== */

/**
 * For development
 */

// export const BASE_URL = "https://glp.techtonic.asia/user"; // For develop
// export const TRANSLATE_URL = `https://glp.techtonic.asia/user/tarnslate?`; // develop
// export const BASE_URL_IMG = "https://d1v4waqh4od8qe.cloudfront.net"; // develop
// export const ENDPOINT = "https://glp.techtonic.asia";
/**
 * For Production
 */
export const ENDPOINT = "https://api.glp-connect.org"
export const BASE_URL_IMG = 'https://assests.glp-connect.org' // production
export const BASE_URL = 'https://api.glp-connect.org/user' //   For Production
export const TRANSLATE_URL = `https://api.glp-connect.org/user/tarnslate?`;//   For Production

/**====================================================End=========================================================== */

axios.defaults.baseURL = BASE_URL;

const token = localStorage.getItem("token");

export const loginService = async (data) => {
  try {
    const response = await axios.post(LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const SignupApi = async (data) => {
  try {
    const response = await axios.post(SIGNUP, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const loginServiceFirst = async (data) => {
  try {
    const response = await axios.post(LOGIN_FIRST, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const changePasswordApi = async (data) => {
  try {
    const response = await axios.post(CHANGEPASSWORD_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const otpVerificationApi = async (data) => {
  try {
    const response = await axios.post(OTPVERIFICATION_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const otpVerificationLoginApi = async (data) => {
  try {
    const response = await axios.post(OTPVERIFICATION_API_LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const forgotPasswordApi = async (data) => {
  try {
    const response = await axios.post(FORGOTPASSWORD_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getSectorApi = async (data) => {
  try {
    const response = await axios.post(GET_SECTOR, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getLanguageApi = async () => {
  try {
    const response = await axios.get(GET_LANGUAGE);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

// Formsection api //

export const formServiceapi = async (data) => {
  try {
    const response = await axios.post(FORMSERVICE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const formServiceActiveApi = async (data) => {
  try {
    const response = await axios.post(GETALLACTIVETHREATS, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const formServicepollsApi = async (data) => {
  try {
    const response = await axios.post(GETALLPOLL, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const formFilterApi = async (data) => {
  try {
    const response = await axios.post(FILTER_FORMSERVICE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const formSearchApi = async (data) => {
  try {
    const response = await axios.post(SEARCH_FORM, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const likeandunlikeservice = async (data) => {
  try {
    const response = await axios.post(LiKE_END_UNLIKE_FORMSER, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const bookmarkservice = async (data) => {
  try {
    const response = await axios.post(BOOK_MARK, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getSaveFormService = async () => {
  try {
    const response = await axios.get(SAVE_FORM);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getNotifactionCount = async () => {
  try {
    const response = await axios.get(NOTIFICATION_COUNT);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};


export const getALLNotifactionCount = async () => {
  try {
    const response = await axios.post(NOTIFICATION_ALL_COUNT);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};



export const getTagFormService = async () => {
  try {
    const response = await axios.get(GET_TAG);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getComments = async (_id) => {
  try {
    const response = await axios.get(`${GET_COMMENTS}/${_id}`);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const FormDeleteComment = async (data) => {
  try {
    const response = await axios.post(DEL_FORM_COMMENTS, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const FormEditComment = async (data) => {
  try {
    const response = await axios.post(EDIT_FORM_COMMENTS, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const FormReportComment = async (data) => {
  try {
    const response = await axios.post(REPORT_FORM_COMMENT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const FormSuggest = async (data) => {
  try {
    const response = await axios.post(SUGGEST, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const FormReplyLikes = async (data) => {
  try {
    const response = await axios.post(likereply, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

//comment//

export const addCommentForum = async (data) => {
  try {
    const response = await axios.post(ADD_COMMENTS, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const galleryServiceapi = async (data) => {
  try {
    const response = await axios.post(GALLERYSERVICE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const galleryLikeapi = async (data) => {
  try {
    const response = await axios.post(LiKEGALLERY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const galleryLikeReplayapi = async (data) => {
  try {
    const response = await axios.post(LIKE_REPLY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const galleryEditapi = async (data) => {
  try {
    const response = await axios.post(EDIT_POST_GALLERY_COMMENT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getGalleryComments = async (data) => {
  try {
    const response = await axios.post(GET_GALLERY_COMMENTS, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addCommentGallery = async (data) => {
  try {
    const response = await axios.post(ADD_COMMENT_GALLERY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const SearchGalleryapi = async (data) => {
  try {
    const response = await axios.post(SEARCH_POST_GALLERY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const galleryDeleteComment = async (data) => {
  try {
    const response = await axios.post(DELETE_COMMENT_GALLERY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const galleryDeletePost = async (_id) => {
  try {
    const response = await axios.post(`${DELETE_POST_GALLERY}/${_id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const galleryReportPost = async (data) => {
  try {
    const response = await axios.post(REPORT_POST_GALLERY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const galleryReportComment = async (data) => {
  try {
    const response = await axios.post(REPORT_COMMENT_GALLERY, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const galleryEdittPost = async (data) => {
  try {
    const response = await axios.post(EDIT_POST_GALLERY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getProfile = async (data) => {
  try {
    const response = await axios.post(PROFILE, data);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

// resuource//

export const getCategary = async (_id) => {
  try {
    const response = await axios.get(GET_CATEGARY);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getResource = async (data) => {
  try {
    const response = await axios.post(GET_RESOURCE, data);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

//toolkit//

export const getToolkit = async () => {
  try {
    const response = await axios.get(GET_TOOLKIT);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const ToolSearchApi = async (data) => {
  try {
    const response = await axios.post(SEARCH_TOOLKIT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

//notifaction //

export const getNotifaction = async (page = 1, size = 10) => {
  try {
    const response = await axios.get(`${GET_NOTIFACTION}?page=${page}&size=${size}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

//news //

export const getNewsFeed = async (data) => {
  try {
    const response = await axios.post(NEWSFEED, data);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getSearchNewsFeed = async (data) => {
  try {
    const response = await axios.post(SEARCHNEWSFEED, data);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getNewsFeedFilter = async (data) => {
  try {
    const response = await axios.post(NEWSFEEDFILTER, data);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getNewsFeedSave = async (data) => {
  try {
    const response = await axios.post(NEWSFEED, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const newsFeedBookMark = async (data) => {
  try {
    const response = await axios.post(BOOKMARKNEEWSFEED, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

//evnts //

export const getEvent = async (data) => {
  try {
    const response = await axios.post(EVENTS, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

// stories //

export const getStories = async (data) => {
  try {
    const response = await axios.post(STORIES, data);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const storyFilterApi = async (data) => {
  try {
    const response = await axios.post(FILTER_POST, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const storySearchApi = async (data) => {
  try {
    const response = await axios.post(SEARCH_STORY_POST, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const likeStory = async (data) => {
  try {
    const response = await axios.post(LIKESTORIES, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const storyReplyLikes = async (data) => {
  try {
    const response = await axios.post(LIKE_RPLY_STORY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const AddCommentStory = async (data) => {
  try {
    const response = await axios.post(ADDCOMMENTSTORY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getStoryComments = async (_id) => {
  try {
    const response = await axios.get(`${COMMENTSTORY}/${_id}`);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const storyEditcomment = async (data) => {
  try {
    const response = await axios.post(EDIT_COMENT_STORY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const storyDeletecomment = async (data) => {
  try {
    const response = await axios.post(DELETE_STORY_COMMENT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const storyLikecomment = async (data) => {
  try {
    const response = await axios.post(LIKE_STORY_COMMENT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addPostGallery = async (data) => {
  try {
    const response = await axios.post(ADD_POST, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getDirectoryApi = async () => {
  try {
    const response = await axios.get(DIRETORY);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getMsgApi = async (data) => {
  try {
    const response = await axios.post(MESSSAGES, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const sendMsgApi = async (data) => {
  try {
    const response = await axios.post(SEND_MESSSAGES, data, {
      headers: { "Content-Type": "multipart/form-data", },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAboutusApi = async () => {
  try {
    const response = await axios.get(ABOUT_US);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getDesclaimerUsApi = async () => {
  try {
    const response = await axios.get(DESCLAIMER);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getCodeOfffConductApi = async () => {
  try {
    const response = await axios.get(CODE_OF_CONDUCT);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getFaqApi = async () => {
  try {
    const response = await axios.get(FAQ);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getAvtarApi = async () => {
  try {
    const response = await axios.get(EDIT_AVATAR_PROFILE);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const editProfileApi = async (data) => {
  try {
    const response = await axios.post(EDIT_PROFILE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const mobileVerifictionApi = async (data) => {
  try {
    const response = await axios.post(SENDVERIFYCODE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const mobileVerifictionOTPApi = async (data) => {
  try {
    const response = await axios.post(VERIFYCODE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const blockUser = async (_id) => {
  try {
    const response = await axios.post(`${BLOCK}/${_id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const blockUserList = async (_id) => {
  try {
    const response = await axios.post(`${BLOCKLIST}/${_id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const DeleteUser = async (data) => {
  try {
    const response = await axios.post(DELETE_PROFILE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const ViewResource = async (data) => {
  try {
    const response = await axios.post(VIEW_COUNT_RESOURCE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const ViewToolkitCount = async (data) => {
  try {
    const response = await axios.post(VIEW_COUNT_TOOLKIT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const DownloadResourceCount = async (data) => {
  try {
    const response = await axios.post(DOWNLOAD_COUNT_RESOURCE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const DownloadToolCount = async (data) => {
  try {
    const response = await axios.post(DOWNLOAD_COUNT_TOOLKIT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const NotificationRead = async (data) => {
  try {
    const response = await axios.post(NOTIFICATION_READ, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const SingleNotificationRead = async (data) => {
  try {
    const response = await axios.post(SINGLE_NOTIFICATION_READ, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const directorySearchApi = async (data) => {
  try {
    const response = await axios.post(DIRECTORYSEARCH, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getSkillTrainning = async () => {
  try {
    const response = await axios.get(SKILLTRAINNING);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const SkillSession = async (data) => {
  try {
    const response = await axios.post(SKILLSESSION, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const SkillComplete = async (data) => {
  try {
    const response = await axios.post(COPLETE_SEBSESSION, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GetSubSession = async (data) => {
  try {
    const response = await axios.post(GET_SUB_SESSION, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const LogoutProfile = async (data) => {
  try {
    const response = await axios.post(LOGOUT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const CourseFilter = async (data) => {
  try {
    const response = await axios.post(COURES_FILTER, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const CourseSearch = async (data) => {
  try {
    const response = await axios.post(COURSE_SEARCH, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const enrollCoursed = async (data) => {
  try {
    const response = await axios.post(ENROLL_COURSE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const dowanloadpdfApi = async (data) => {
  try {
    const response = await axios.post(DOWNLOADPDF, data, {
      headers: { "Content-Type": "application/json" },
      responseType: "blob",
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const matchAliasName = async (data) => {
  try {
    const response = await axios.post(ALIASMATCH, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getBlockUserData = async (_id) => {
  try {
    const response = await axios.post(`${BLOCKLIST}/${_id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const userPoll = async (data) => {
  try {
    const response = await axios.post(POLL, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const userPollV2 = async (data) => {
  try {
    const response = await axios.post(POLLV2, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getSaveNewApi = async () => {
  try {
    const response = await axios.post(GETSAVENEWS);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

/**
 *
 * By Anubhav Sharma
 *
 */

// Mention server
export const MentionData = async (data) => {
  try {
    const response = await axios.post(MENTIONAPI, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

// Report Api For User Profile

export const ReportUser = async (data) => {
  try {
    const response = await axios.post(USERREPORTSPI, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

// Cousre Download Api For User Profile

export const Download_course = async (data) => {
  try {
    const response = await axios.post(DOWNLOADCOURSEAPI, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const ResetpasswordApi = async (data) => {
  try {
    const response = await axios.post(RESRT_PASSWORD, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetMeetingCode = async (data) => {
  try {
    const response = await axios.post(GET_MEETING_CODE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CompleateTask = async (data) => {
  try {
    const response = await axios.post(TASKCOMPLETE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};


export const Single_Resourse = async (data) => {
  try {
    const response = await axios.post(SINGLE_RESOURCE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};



export const AddFcmToken= async (data) => {
  try {
    const response = await axios.post(FCM_TOKEN_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};