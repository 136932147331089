import { AddFcmToken, getLanguageApi, loginServiceFirst } from "../../Utils/services";
import { useState, useContext } from "react";
import { toast } from "react-toastify";
import mibilelogo from "../../Assets/images/glplogotwo.png";
import AuthContext from "../../Utils/auth-context";

import { useTranslation } from "react-i18next";
import CornerImg from "../../Assets/images/signincorner.png";
import { useEffect } from "react";
import { Globalinformation } from "../../Common/MainSectionProvider";
import { getToken } from "../firebaseInit.js";
import { Link } from "react-router-dom";
import CountryModal from "../../ModalTemplate/CountryModal";
import india from "../../Assets/images/country/india.png";
import { useNavigate } from "react-router/dist";
import {detectBrowserAndDevice} from "../Functions.js"
const Login = (props) => {
  const { t, i18n } = useTranslation(["home"]);
  // {`${t('Age')}`}
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [deviceToken, setDeviceToken] = useState("");
  const { setCountrypostallow } = useContext(Globalinformation);

  const [userData, setUserData] = useState({
    phone: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [language, setLanguage] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [countryCode, setCountryCode] = useState({
    Icon: india,
    Code: "91",
    cntyCode: "+91",
  });

  useEffect(() => {
    if (userData?.phone === "") {
      setIsButtonActive(false);
    } else if (userData?.phone !== "") {
      setIsButtonActive(true);
    }
  }, [userData]);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = {
        phone: userData.phone,
        password: userData.password,
        fcmToken: deviceToken,
        code: countryCode?.Code,
      };
      const result = await loginServiceFirst(data);
      const deviceInfo = await detectBrowserAndDevice()

      const response = await AddFcmToken({
        device_id:deviceInfo.deviceid,
        device_name:deviceInfo.devicename,
        fcm_token:deviceToken
      })


      if (result.res.success) {
        // setLoading(false);
        // localStorage.setItem('deviceToken', deviceToken)
        // history.push({
        //     pathname: "/loginOtp",
        //     state: {
        //         fcmToken: deviceToken,
        //         phone: userData.phone,
        //     },
        // })
        //console.log((result.res.data.refreshToken, "login");
        localStorage.setItem("aliasName", result.res.data.user.aliasName);
        localStorage.setItem("adminId", result.res.data.user._id);
        localStorage.setItem("adminName", result.res.data.user.name);
        localStorage.setItem("language", result.res.data.user.language.name);
        localStorage.setItem("country", result.res.data.user.country.name);
        localStorage.setItem(
          "countrypost",
          result.res.data.user.country.isPostAllow
        );
        localStorage.setItem(
          "phoneverification",
          result.res.data.user.isverify
        );
        localStorage.setItem("phone", result.res.data.user.phone);
        localStorage.setItem("fcmToken", deviceToken);
        localStorage.setItem("lng", result.res.data.user.language.name);
        toast.success(result.res.message || "User Authenticated!");
        authCtx.login(
          result.res.data.accessToken,
          result.res.data.refreshToken,
          result.res.data.isSuperAdmin
        );
        changeLanguage(result.res.data.user.language.name);
        setLoading(false);
        toast.success(result.res.message);
        console.log(props, "props.history");

        if (props.history.length > 0) {
          var path = props.history[props.history.length - 1];
          props.setHistory([]);
          navigate(path);
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };
  // New
  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    loginSubmit(e);
  };

  const handleClicksignUp = () => {
    navigate("/signup");
  };

  const handleClickForgotApp = () => {
    // //console.log((deviceToken);
    // history.push("/forgotpassword")
    navigate("/forgotpassword", {
      state: {
        fcmToken: deviceToken,
      },
    });
  };

  //  useEffect(() => {
  //     if (firebase.messaging.isSupported()) {
  //       const msg = firebase.messaging();
  //       msg.requestPermission().then(() => {
  //         return msg.getToken();
  //       }).then((data) => {
  //         setDeviceToken(data)
  //         //console.log((data, "token Data")
  //       })
  //     }
  //   })

  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getToken(setDeviceToken);
      if (data) {
        //console.log(("Token is", data);
        setDeviceToken(data);
      }
      return data;
    }

    tokenFunc();
  }, [setDeviceToken]);

  useEffect(() => {
    changeLanguage("English");
    const fetchLanguageData = async () => {
      const result = await getLanguageApi();
      //console.log((result.res.data.languages, "get language");
      setLanguage(result.res.data.languages);
    };

    fetchLanguageData();
  }, []);

  const handlelange = (e) => {
    const lang = e.target.value;
    localStorage.setItem("lng", lang);
    changeLanguage(lang);
    // window.location.reload();
  };

  return (
    <div className="w-2/3 mx-auto auth-container">
      <div className="flex justify-content-center">
        <select
          name=""
          id=""
          className="ml-52 languageselect"
          onChange={handlelange}
        >
          <option value=""> {`${t("language")}`}</option>
          {language &&
            language.map((item) => (
              <option value={item.name} key={item._id}>{item.name}</option>
            ))}

          {/* <option value="en">English</option> */}
        </select>
      </div>

      <img
        src={CornerImg}
        alt="corner img"
        className="h-24 w-24 absolute right-0 top-0 cornerImg"
      />
      <div className="w-1/2 mx-auto mobileformscreen">
        <div className="flex justify-center mobilelogo">
          <img src={mibilelogo} alt="" />
        </div>
        <h2 className="primary-color font-sans text-2xl	 font-bold mb-10 mobilescreentitle ">
          {`${t("Sign In")}`}
        </h2>
        <div className="auth-form authFormInputStyle">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="flex ">
              <div
                className="flex items-center countrycode cursor-pointer"
                onClick={() => setModalShow(true)}
              >
                <div className="country-img">
                  <img src={countryCode.Icon} alt="" />
                </div>

                <p>{countryCode.cntyCode}</p>
              </div>

              <input
                type="text"
                placeholder={`${t("Phone Number")}`}
                name="phone"
                value={userData.phone}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>

            <input
              type="password"
              placeholder={`${t("Enter Password")}`}
              name="password"
              value={userData.password}
              onChange={(e) => handleChange(e)}
              required
            />
            <button
              className={`${
                isButtonActive ? "ActiveGetOtpBtn" : "inactiveGetOtpBtn"
              }`}
            >{`${t("Sign In")}`}</button>
          </form>
          <span className="mt-2 cursor-pointer" onClick={handleClickForgotApp}>
            {`${t("Forgot Password")}`}?
          </span>
        </div>

        <div className="text-center signUpSectionLogin">
          <p>{`${t("Not Registered Yet")}`}</p>
          <button className="btn signUpBtn" onClick={handleClicksignUp}>{`${t(
            "Sign Up"
          )}`}</button>
        </div>
        <div className="loginPrivacyPolicy">
          <Link to={"/privacy"}>{`${t("Privacy policy")}`}</Link>
        </div>
      </div>
      <CountryModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
        setCountryCode={setCountryCode}
      />
    </div>
  );
};

export default Login;
